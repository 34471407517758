body { background-color: #ffffff; }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: .2em;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 50px;

}
}
h2 {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: .2em;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h3 {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: .2em;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h4 {
color: #000000;
font-family: 'Arial';
font-weight: normal;
font-style: normal;
line-height: 1.4;
letter-spacing: 0;
font-size: 11px;

@media #{$medium-up} {
font-size: 14px;

}
@media #{$large-up} {
font-size: 16px;

}
}
h5 {
font-family: 'Arial';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: .6em;
font-size: 16px;

}
h6 {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.3;
text-transform: uppercase;
letter-spacing: 1.41em;
font-size: 15px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 32px;

}
}
.button {
font-family: 'Arial';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
.me-Quote .quote-author {
font-family: 'Arial';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Arial';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 8px;

@media #{$medium-up} {
font-size: 10px;

}
@media #{$large-up} {
font-size: 14px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Arial';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 26px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: .2em;
font-size: 8px;

@media #{$medium-up} {
font-size: 15px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: .2em;
font-size: 8px;

@media #{$medium-up} {
font-size: 15px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 11px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Avant Garde';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 11px;

@media #{$medium-up} {
font-size: 14px;

}
}
body {
font-family: 'Arial';
font-weight: normal;
font-style: normal;
line-height: 1.15;
letter-spacing: 0;
font-size: 8px;

@media #{$medium-up} {
font-size: 10px;

}
@media #{$large-up} {
font-size: 14.4px;

}
}
p { padding-bottom: 1em; }
a {color: #000000;}
a:hover {color: var(--clr-3368);}
/* Primary:2 */
.MES2 {
background-color: #000000;
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: #000000;
color: #ffffff;
 }
.MES3 {
background-color: #000000;
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-3369);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-3369);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-3369);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-3370);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-3370);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-3370);
 }
.MES8 {
background-color: var(--clr-3370);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-3370);
 }
.MES9 {
background-color: var(--clr-3370);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-3371);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-3371);
 }
.MES11 {
background-color: var(--clr-3371);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-3372);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-3372);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-3372);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 6.4px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 11.52px;
};
 }
.MES14 {
font-size: 6.4px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 11.52px;
};
 }
cite.MEC14{
font-size: 6.4px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 11.52px;
};
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-3370);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-3371);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-3370);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-3371);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-3371);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: #000000;
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
@media #{$large-up} {
& > .menu-item > a{padding: 0 0 0 5px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC17.active { & > a{ color: #000000;}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-3373);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: #000000;
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-3371);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: #000000;
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-3371);
color: #000000;
font-size: 9.8px;
padding: 10px 20px;

 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-3371);
font-size: 6.4px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 11.52px;
};
 }
.MES22 {
background-color: var(--clr-3371);
font-size: 6.4px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 11.52px;
};
 }
cite.MEC22{
font-size: 6.4px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 11.52px;
};
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-3370);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-3370);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
/* border line:25 */
.MES25 {
@media #{$large-up} {
padding: 40px 0;

}
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-3370) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: #000000;
font-size: 8px;
@media #{$medium-up} {
font-size: 10px;
};
@media #{$large-up} {
font-size: 11.52px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-3371) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: #000000;
font-size: 8px;
@media #{$medium-up} {
font-size: 10px;
};
@media #{$large-up} {
font-size: 11.52px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-3371) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-3369);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 50px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 11.2px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 16px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 17.6px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 32px; }; }
 }
a.MEC26 { color: #000000;
&:hover { color: var(--clr-3369);}
 }
cite.MEC26{
color: #000000;
font-size: 8px;
@media #{$medium-up} {
font-size: 10px;
};
@media #{$large-up} {
font-size: 11.52px;
};
}
/* Transparent Panel White:27 */
.MES27 {
background-color: var(--clr-3374);
 }
.MES27 {
background-color: var(--clr-3374);
 }
/* Transparent Panel Black:28 */
.MES28 {
background-color: var(--clr-3375);
 }
.MES28 {
background-color: var(--clr-3375);
 }
/* Link Panel Style:29 */
.MES29 {
background-color: #ffffff;
color: #000000;
 }
.MES29 {
background-color: #ffffff;
color: #000000;
 }
a.MEC29 { color: #000000;
&:hover { color: var(--clr-3368);}
 }
cite.MEC29{
color: #000000;
}
/* Main Menu:30 */
nav.me-Menu.MES30 {
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: #000000;
text-transform: uppercase;
}
  }
&.horizontal > .menu-item.MEC30 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 30px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 0;}

}
& .sub-menu .menu-item a{padding: 30px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Close Button:31 */
.MES31 {
font-size: 21.28px;
@media #{$large-up} {
font-size: 21px;
};
 }
/* Special form Button:32 */
.MES32 {
background-color: transparent;
color: #000000;
padding: 15px;

 }
/* :33 */
.me-block > .field-wrap.MES33 { 
& label {  }
 }
/* form border:34 */
.MES34 {
background-color: transparent;
border-width: 1px;
border-style: solid;
border-color: #000000;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES34 {
background-color: transparent;
border-width: 1px;
border-style: solid;
border-color: #000000;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* White background:36 */
.MES36 {
background-color: #ffffff;
 }
.MES36 {
background-color: #ffffff;
 }
/* Inquire Button:37 */
.MES37 {
color: #000000;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: #000000;
 }
